import { useState, useEffect } from 'react';
import JGTransposer from '../pages/Transposer/JGTransposer';
import MessageAlert from './MessageAlert';
import "./MessageAlert.css";
import {useDispatch,useSelector} from 'react-redux';
import {getConferenceLink} from './../util/action';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function Search(){
    const [value, setValue] = useState('');
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [song, setSong] = useState([]);
    const [fastSong, setFastSong] = useState([]);
    const [slowSong, setSlowSong] = useState([]);
    const dispatch = useDispatch();
    const conference = useSelector(state=>state.MinistryReducer.conference);
    const [messageAlert, setMessageAlert] = useState(false);
    const bannerClasses = !messageAlert
    ? "message-banner hide"
    : "message-banner show";
   

    useEffect(() =>{
        dispatch(getConferenceLink());
        fetch('https://api.v1.pottershousephils.com/v1/p&w/songs')
        .then(response => {
            return response.json();
        }).then(responseData => {
            if(value.length > 0){
                setFilteredContacts(
                    responseData.filter(
                    (song) => song.title.toLowerCase().includes(value.toLowerCase()) 
                    ).slice(0,7)
                );
            }
            setFastSong(responseData.filter((song) => song.type.includes('F')));
            setSlowSong(responseData.filter((song) => song.type.includes('S')));
        });
    }, [value]);

    function goto(link){
        //window.location.href=link;
        window.open(link,'_blank');
    }
    
    function getChords(song)
    {
        scroller.scrollTo('chordslayout', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: 50, // Scrolls to element + 50 pixels down the page
        })
        console.log(song);
        setSong(song);
        setValue(song.title);
    }

    const showBanner = event => {
        event.preventDefault();
        setMessageAlert(true);
        setTimeout(() => {
            setMessageAlert(false);
            setValue('');
        }, 3000);
    };

    return(
        <div>
            <div class="bg-stone-900 mx-auto lg:p-10 sm:p-4 md:p-10 text-white">
                <div class="p-4">
                    <h1 class="text-center font-bold text-white text-4xl">Praise & Worship</h1>
                    <p class="mx-auto font-normal text-center my-6 max-w-lg text-white">
                        More than 500+ songs and we're adding more. Search the title or lyrics of the song and transpose to your preferred key notes down below.
                    </p>
                    <div class="grid justify-items-center">
                        <div class="bg-white rounded-lg overflow-hidden px-2 py-2 w-full">
                            <input class="text-stone-400 outline-none" onChange={(event) => setValue(event.target.value)} value={value} type="text" placeholder="Search Title of the Song" />
                        </div>
                    </div>
                    {(filteredContacts.length == 0 && value.length > 0 && !messageAlert)  &&
                    <div className="row">
                        <h3 class="mt-4">NOT FOUND</h3>
                        {/* <div class="p-2">
                            <div class="inline-flex items-center bg-white leading-none text-gray-600 rounded-full p-2 shadow text-teal text-sm">
                                <span class="inline-flex px-2">Request this song to admin.</span>
                                <button onClick={showBanner} class="inline-flex bg-indigo-600 text-white rounded-full h-6 px-3 justify-center items-center hover:bg-indigo-light">REQUEST</button>
                            </div>
                        </div> */}
                    </div>
                    } 
                    {messageAlert &&
                        <MessageAlert open={bannerClasses} text={value} />
                    }
                </div>
               
                <div class="bg-white rounded max-h-select overflow-y-auto m-4">
                    {filteredContacts.map((song) => [
                        <div class="cursor-pointer w-full border-gray-100 border-b hover:bg-teal-400" onClick={() => getChords(song)} key={song.id}>
                            <div class="flex w-full p-4 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                <div class="w-full flex">
                                    <div class="mx-2 -mt-1 text-gray-600 capitalize text-left">
                                        {song.title}
                                        <div class="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500">
                                            ORIGINAL KEY: {song.key}
                                        </div>
                                    </div>
                                    <div class="flex justify-center items-center capitalize font-medium">
                                        <div className={(song.type == 'F')? 'text-red-200 bg-red-600 border-red-300 bg-white rounded-full border px-2 py-1 m-1': 'text-sky-200 bg-sky-600 border-sky-600 bg-white rounded-full border px-2 py-1 m-1'}>
                                            <div class="text-xs font-normal leading-none max-w-full flex-initial">{(song.type == 'F')? 'FAST' : 'SLOW' }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ])}
                </div>
                <br/>
            </div>
            <div class="grid lg:grid-cols-3 sm:grid-cols-1 mx-auto py-10 justify-center md:w-4/5 lg:w-4/5">
	            <div class="w-full mb-6 lg:pl-4">
                    <div class="bg-stone-900 text-sm text-white font-bold px-5 py-2 shadow border-b border-gray-300">
                        Fast Song
                    </div>
                    <div class="w-full h-64 overflow-auto shadow bg-white" id="journal-scroll">
                        <table class="w-full">
                            <tbody class="">
                                {fastSong.map((song) => [
                                    <tr class="group relative transform scale-100 text-xs py-1 border-b-2 border-stone-200 cursor-default hover:bg-blue-900" onClick={() => getChords(song)}  >
                                        <td class="pl-5 pr-3 whitespace-no-wrap">
                                            <div class="text-stone-400 group-hover:text-white">KEY</div>
                                            <div class="text-stone-500 group-hover:text-white font-medium">{song.key}</div>
                                        </td>
                                        <td class="px-2 py-2 whitespace-no-wrap">
                                            <div class="leading-5 text-stone-500 group-hover:text-white font-medium">{song.title}</div>
                                            <div class="leading-5 text-stone-800 group-hover:text-white">{song.title}</div>
                                        </td>
                                    </tr>
                                ])}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="w-full mb-6 lg:pl-4">
                    <div class="bg-stone-900 text-sm text-white font-bold px-5 py-2 shadow border-b border-gray-300">
                        Slow Song
                    </div>
                    <div class="w-full h-64 overflow-auto shadow bg-white" id="journal-scroll">
                        <table class="w-full">
                            <tbody class="">
                                {slowSong.map((song) => [
                                   <tr class="group relative transform scale-100 text-xs py-1 border-b-2 border-stone-200 cursor-default hover:bg-blue-900" onClick={() => getChords(song)} >
                                        <td class="pl-5 pr-3 whitespace-no-wrap">
                                            <div class="text-stone-400 group-hover:text-white">KEY</div>
                                            <div class="text-stone-500 group-hover:text-white font-medium">{song.key}</div>
                                        </td>
                                        <td class="px-2 py-2 whitespace-no-wrap">
                                            <div class="leading-5 text-stone-500 group-hover:text-white font-medium">{song.title}</div>
                                            <div class="leading-5 text-stone-800 group-hover:text-white">{song.title}</div>
                                        </td>
                                    </tr>
                                ])}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="w-full lg:pl-4">
                    <div class="bg-stone-900 text-sm text-white font-bold px-5 py-2 shadow border-b border-gray-300">
                        Conference Song Sheet
                    </div>
                    <div class="w-full h-64 overflow-auto shadow bg-white" id="journal-scroll">
                        <table class="w-full">
                            <tbody class="">
                                {conference.map((conf) => [
                                   <tr class="group relative transform scale-100 text-xs py-1 border-b-2 border-stone-200 cursor-default hover:bg-blue-900" onClick={() => goto(conf.link)} >
                                        <td class="pl-5 pr-3 whitespace-no-wrap">
                                            <div class="text-stone-400 group-hover:text-white">Year</div>
                                            <div class="text-stone-500 group-hover:text-white font-medium">{conf.year}</div>
                                        </td>
                                        <td class="px-2 py-2 whitespace-no-wrap">
                                            <div class="leading-5 text-stone-500 group-hover:text-white font-medium">{conf.month}</div>
                                            <div class="leading-5 text-stone-800 group-hover:text-white">{conf.theme}</div>
                                        </td>
                                    </tr>
                                ])}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Element name="chordslayout" className="element">
                <JGTransposer title={song.title} link={song.file_link} fileid={song.file_id} chordsKey={song.key} chordsId={song.id} chords={song.chords} key={song.id}/>
            </Element>
        </div>
    );
}

export default Search;