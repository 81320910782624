const initialState = {
    curriculum: [],
    loader: true,
    dramaScript: [],
    dramaTypes: [],
    one80Song:[],
    one80SongAll:[],
    conference:[],
    tags:[],
    allTags:[],
    currentTag:'All Song',
    randomImage:'https://api.v1.pottershousephils.com/home/images/mandaluyong/_DSC2769.JPG'
}

const MinistryReducer = (state=initialState, action) => {
    const newState = {...state};
    switch(action.type){
        case 'FETCH_CURRICULUM':
            return {
                ...state,
                curriculum: action.payload,
                loader: false
            }
        case 'FETCH_DRAMA_SCRIPT':
            return {
                ...state,
                dramaScript: action.payload,
                loader: false
            }
        case 'FETCH_DRAMA_TYPES':
            return {
                ...state,
                dramaTypes: action.payload,
                loader: false
            }
        case 'FILTER_DRAMA_TYPES' :
            console.log(action.payload.type);
            let value = action.payload.type;
            let filteredValues = state.dramaScript.filter(scirpt => {
                return scirpt.type.includes(value);
            });
            return {
                ...state,
                dramaScript: filteredValues,
                loader: false
            }
        case 'FETCH_ONE80JAM_SONG':
            return {
                ...state,
                one80Song: action.payload,
                one80SongAll: action.payload,
                allTags:action.tags,
                loader: false
            }
        case 'FETCH_ONE80JAM_SONG_TAGS':
            return {
                ...state,
                one80SongAll: action.payload,
                currentTag: action.tag,
                loader: false
            }
        case 'SEARCH_ONE80JAM_SONG_TAGS':
            return {
                ...state,
                one80Song: action.payload,
                loader: false
            }
        case 'GET_ONE80JAM_SONG':
            return {
                ...state,
                one80Song: action.payload,
                tags: action.tags,
                loader: false
            }
        case 'GET_RANDOM_PICTURE':
            return {
                ...state,
                randomImage: action.payload,
                loader: false
            }
        case 'FETCH_CONFERENCE':
            return {
                ...state,
                conference: action.payload,
                loader: false
            }
    }
    return newState;
    
}

export default MinistryReducer;