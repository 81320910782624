import React, { useState, useEffect } from 'react';
import Loader from '../component/Loader';
import  {useLocation} from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import {getGoogleLink, setToken,fetchUser, getRandomPicture} from '../util/action';

function Login(){
    const googleLink = useSelector(state=>state.UserReducer.google);
    const user = useSelector(state=>state.UserReducer.user);
    const randomImg = useSelector(state=>state.UserReducer.randomImage);
    const loader = useSelector(state=>state.UserReducer.loader);
    const authenticated = useSelector(state=>state.UserReducer.authenticated);
    const dispatch = useDispatch();
    const location = useLocation();

    const handleLogOut = () =>{
        localStorage.clear();
        window.location.href='login';
    };
    
    useEffect(()=>{
        dispatch(getRandomPicture());
        if(authenticated && !user.email){
            dispatch(fetchUser());
        }else if(location.search){
            dispatch(setToken(location.search));
        }else{
            if(!authenticated){
                dispatch(getGoogleLink());
            }
        }
    },[]);

    return(
        <section class="flex flex-col md:flex-row h-screen items-center">
            <div class="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
                <img src='https://api.v1.pottershousephils.com/home/images/mandaluyong/manda5.jpg' alt="" class="w-full h-full object-cover"/>
            </div>
      
            <div class="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
                <div class="w-full h-100">
                {loader  ? (
                    <Loader />
                    ):(
                        <div class="w-full h-100">
                            {!authenticated ? (
                                <div class="grid items-center justify-center">
                                    <h1 class="text-xl md:text-2xl font-bold leading-tight text-center">Log in to your account</h1>
                                    <span class="text-xs text-center mt-4">You don't need to register</span>
                                </div>
                            ):(
                                <h1 class="text-xl md:text-2xl font-bold leading-tight text-center">{user.email}</h1>
                            )}
                            <hr class="my-6 border-gray-300 w-full"/>
                            {!authenticated ? (
                                <a href={googleLink} type="button" class="w-full block bg-white hover:bg-gray-100 focus:bg-gray-100 text-gray-900 font-semibold rounded-lg px-4 py-3 border border-gray-300">
                                    <div class="flex items-center justify-center">
                                        <span class="ml-4">Login with Google</span>
                                    </div>
                                </a>      
                            ):(
                                <div class="flex justify-center">
                                    <button onClick={handleLogOut} class="bg-red-800 hover:bg-red-900 text-white font-bold py-2 px-4 rounded">
                                        Log out
                                    </button>
                                </div>
                            )} 
                        </div>
                     )} 
                </div>
            </div>
      
        </section>
    );
}
export default Login;