import  {Link} from 'react-router-dom';
import logo from '../files/phcc.png';
import { useState } from 'react';

function MainNav(){
    const [navbar, setNavbar] = useState(false);
 
    return(
        <header class="text-stone-300 capitalize bg-stone-900">
            <nav class="w-full flex relative justify-between items-center">
                <div class="p-6 mx-auto items-center">
                    <div class="p-4 flex flex-row items-center justify-between text-stone-200">
                        <a class="block text-center" href="/">
                            <img class="inline md:w-4/12 sm:w-2/5 xs:w-96" src={logo} />
                        </a>
                        <div class="flex items-center block xs:hidden sm:hidden">
                            {/* Hamburger Menu */}
                            <button onClick={() => setNavbar(!navbar)} class="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    {navbar &&
                        <div class="block xs:hidden sm:hidden">
                            <div class="px-2 pt-2 pb-3 space-y-1">
                                <Link onClick={() => setNavbar(false)} to="/" class="text-gray-300 hover:bg-red-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</Link>
                                <Link onClick={() => setNavbar(false)} to="/about-us" class="text-gray-300 hover:bg-red-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">About Us</Link>
                                <Link onClick={() => setNavbar(false)} to="/chords" class="text-gray-300 hover:bg-red-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Chords</Link>
                                <Link onClick={() => setNavbar(false)} to="/login" class="text-gray-300 hover:bg-red-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Login</Link>
                            </div>
                        </div>
                    }
         
                    <div class="text-center hidden xs:block sm:block">
                        <Link class="border-b-2 border-transparent hover:text-stone-800 hover:border-stone-50 mx-1.5 sm:mx-6" to='/'>Home</Link>
                        <Link class="border-b-2 border-transparent hover:text-stone-800 hover:border-stone-50 mx-1.5 sm:mx-6" to='/about-us'>About Us</Link>
                        <Link class="border-b-2 border-transparent hover:text-stone-800 hover:border-stone-50 mx-1.5 sm:mx-6" to='/chords'>Chords</Link>
                        <Link class="border-b-2 border-transparent hover:text-stone-800 hover:border-stone-50 mx-1.5 sm:mx-6" to='/login'>Login</Link>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default MainNav;