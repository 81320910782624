import React from 'react'
import ReactDOM from 'react-dom';
import Gallery from "react-photo-gallery";
import { useEffect, useState } from 'react';
import Dec2023 from '../files/brochure/December 2023.png';

function Brochure(props) {
    return (
		<div style={{ margin: '10px' }}>
			<img src={props.brochures.file} alt={props.brochures.label}/>
		</div>
    );
}

export default Brochure