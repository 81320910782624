import { useState, useEffect } from 'react';
import {fetchOneSong, fetchSearchOne80Song, getOne80Song, fetchTagSong} from '../../util/action';
import {useDispatch,useSelector} from 'react-redux';
import Loader from '../../component/Loader';
import NotLoginPage from '../NotLoginPage';
import Tags from './Tags';
import Lyrics from './Lyrics';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function One80Jam() {
    const song = useSelector(state=>state.MinistryReducer.one80Song);
    const allSong = useSelector(state=>state.MinistryReducer.one80SongAll);
    const tags = useSelector(state=>state.MinistryReducer.tags);
    const currentTag = useSelector(state=>state.MinistryReducer.currentTag);
    const allTags = useSelector(state=>state.MinistryReducer.allTags);
    const authenticated = useSelector(state=>state.UserReducer.authenticated);
    const dispatch = useDispatch();
    const loader = useSelector(state=>state.MinistryReducer.loader);
    const [filteredSong, setFilteredSong] = useState([]);
    const [value, setValue] = useState('');
   
    useEffect(()=>{
        if(allSong.length == 0){
            dispatch(fetchOneSong());
        }
        fetch('https://api.v1.pottershousephils.com/v1/one80Jam',{ headers: {
            "Authorization" : `Bearer ${authenticated}`,
            "Application" : `application/json`
        }})
        .then(response => {
            return response.json();
        }).then(responseData => {
            if(value.length){
                setFilteredSong(
                    responseData.songs.filter((song) => song.song_title.toLowerCase().includes(value) || song.lyrics.toLowerCase().includes(value))
                ); 
            }
           console.log(allSong);
        });
    },[value]);

    function getSong(id) {
        dispatch(getOne80Song(id));
      
        scroller.scrollTo('lyrics-song', {
            duration: 1500,
            delay: 100,
            smooth: true,
        });
        setValue('');
    }

    function setTag(id, name){
        dispatch(fetchTagSong(id, name));
    }
    
    return (
        <div>
        {loader  ? (
                <div class="flex items-center justify-center min-h-screen">
                    <Loader/>
                </div>
            ) :
            (
                <div>
                { authenticated ? 
                    <div>
                        <div class="bg-stone-900 mx-auto lg:p-10 sm:p-4 md:p-10 text-white">
                            <div class="p-4">
                                <h1 class="text-center font-bold text-white text-4xl">one80JAM</h1>
                                <p class="mx-auto font-normal text-center my-6 max-w-lg text-white">
                                    Search the title or lyrics of the song. Christian Version available and other genres. Ideas and themes by using tags below.
                                </p>
                                <div class="grid justify-items-center">
                                    <div class="bg-white rounded-lg overflow-hidden px-2 py-2 w-full">
                                        <input class="text-stone-400 outline-none"type="text" onChange={(event) => setValue(event.target.value)} value={value} placeholder="Search Title of the Song" />
                                    </div>
                                    {allTags &&
                                        <div class="flex flex-wrap justify-center items-center pt-2">
                                            {allTags.map((tag) => [
                                                <div key={tag.id}>
                                                    <div class="p-2">
                                                        <button type="button" onClick={() => setTag(tag.id, tag.name)} class="py-2 px-4 bg-blue-900 text-stone-100 p-2 rounded-full leading-none flex items-center text-xs">
                                                            #{tag.name}
                                                        </button>
                                                    </div>
                                                </div>
                                            ])}
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <Element name="lyrics-song" className="element">
                                { value.length > 0 &&
                                    <div class="bg-white rounded max-h-select overflow-y-auto m-4">
                                        {filteredSong.map((song) => [
                                            <div class="cursor-pointer w-full border-gray-100 border-b hover:bg-teal-400" onClick={() => getSong(song.id)}  key={song.id}>
                                                <div class="flex w-full p-4 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                                    <div class="w-full flex">
                                                        <div class="mx-2 -mt-1 text-gray-600 capitalize text-left">
                                                            {song.song_title}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ])}
                                    </div>
                                }
                            
                                <div class="grid lg:grid-cols-2 sm:grid-cols-1 mx-auto justify-center md:w-4/5 lg:w-4/5">
                                    <div class="w-full mb-6">
                                        <div class="bg-blue-900 text-sm text-white font-bold px-5 py-2 shadow border-b border-gray-300">
                                        #{currentTag}
                                        </div>
                                        <div class="w-full h-64 overflow-auto shadow bg-white" id="journal-scroll">
                                            <table class="w-full">
                                                <tbody class="">
                                                    {allSong.map((song) => [
                                                        <tr class="group relative transform scale-100 text-xs py-1 border-b-2 border-stone-200 cursor-default hover:bg-blue-900" onClick={() => getSong(song.id)}  key={song.id}>
                                                            <td class="pl-5 pr-3 whitespace-no-wrap">
                                                                <div class="text-stone-500 group-hover:text-white font-medium p-2">{song.song_title}</div>
                                                            </td>
                                                        </tr>
                                                    ])}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <Lyrics tags={tags} song={song}/>
                                </div>
                            </Element>
                        </div>
                    </div>
                        :
                        <NotLoginPage/>
                    }
                </div>
            )
        }
        </div>
    )
}

export default One80Jam