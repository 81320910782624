import { useEffect,useState } from 'react';
import ss from '../../files/ss.png';
import {useDispatch,useSelector} from 'react-redux';
import {fetchCurriculum} from '../../util/action';
import NotLoginPage from '../NotLoginPage';
import Loader from '../../component/Loader';

function SSMain(props) {
    const curriculum = useSelector(state=>state.MinistryReducer.curriculum);
    const authenticated = useSelector(state=>state.UserReducer.authenticated);
    const dispatch = useDispatch();
    const loader = useSelector(state=>state.MinistryReducer.loader);

    useEffect(()=>{
        dispatch(fetchCurriculum());
    },[]);
   
    return (
        <div>
            {loader  ? (
                    <div class="flex items-center justify-center min-h-screen">
                        <Loader/>
                    </div>
                ) :
                (
                    <div>
                        { authenticated ? 
                        <div aria-label="group of cards" tabindex="0" class="grid lg:grid-cols-2 sm:grid-cols-1 focus:outline-none py-8 w-full">
                            {curriculum.map((cuur,index) => [
                                <div class="flex items-center justify-center">
                                    <div class="focus:outline-none lg:w-full m-7 bg-stone-100 p-6 shadow rounded" key={index}>
                                        <div class="flex items-center border-b border-gray-200 pb-6">
                                            <img src={ss} alt="coin avatar" class="w-12 h-12 rounded-full" />
                                            <div class="flex items-start justify-between w-full">
                                                <div class="pl-3 w-full">
                                                    <p class="focus:outline-none text-xl font-medium leading-5 text-gray-800">{cuur.title}</p>
                                                    {/* <p class="focus:outline-none text-sm leading-normal text-gray-500">Total LP: {cuur.lpCount}</p> */}
                                                </div>
                                                <button onClick={() => {window.open(cuur.link)}} class="bg-transparent hover:bg-stone-900 text-stone-900 font-semibold hover:text-white py-2 px-4 border border-stone-900 hover:border-transparent rounded">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="focus:outline-none" width="28" height="28" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="px-2">
                                            <p  class="focus:outline-none text-sm leading-5 py-4 text-gray-600">{cuur.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ])}
                        </div>
                        :
                        <NotLoginPage/>
                    }
                    </div>
                )
            }
        </div>
       
    );
}
export default SSMain;