import axios from 'axios';
import React, {useState} from 'react';

function AddStudent() {
    
    const [messageList, setMessageList] = useState('');
    const [disabledForm, setDisabledForm] = useState(false);

    const [formValue, setformValue] = React.useState({
        first_name: '',
        last_name: '',
        age: '',
        address: '',
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false
    });
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabledForm(true);

        axios.post('https://api.v2.pottershousephils.com/api/vbs2024', {
            first_name: formValue.first_name,
            last_name: formValue.last_name,
            age: formValue.age,
            address: formValue.address,
            monday: formValue.monday,
            tuesday: formValue.tuesday,
            wednesday: formValue.wednesday,
            thursday: formValue.thursday,
            friday: formValue.friday
        }).then(res => {
            setMessageList(res.data.message);
            if (res.status == 200) {
                    window.location.href= 'vbs2024';
            }
            setDisabledForm(false);
        })
    };

   const handleChange = (event) => {
        setformValue({...formValue,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeCheckbox = (event) => {
        setformValue({...formValue,
            [event.target.name]: event.target.checked
        });
    }

    const goBackBtn = () => {
        window.location.href='vbs2024';
    }

    return (
       <div class=" place-items-center">
            <div class="bg-blue-100">
                <h1 class="text-xl font-semibold">Add Student</h1>
                 {messageList}
                <form class="mt-6" onSubmit={handleSubmit} disabled={disabledForm}>
                    <label for="first_name" class="block text-xs font-semibold  uppercase">First Name</label>
                    <input id="first_name" type="text" name="first_name" autocomplete="given-name" class="block w-full p-3 
                    mt-2  bg-slate-50 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formValue.first_name}
                    onChange={handleChange}
                    />
                   
                    <label for="last_name" class="block text-xs font-semibold  uppercase">Last Name</label>
                    <input id="last_name" type="text" name="last_name" autocomplete="family-name"
                        class="block w-full p-3 mt-2  bg-slate-50 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formValue.last_name}
                    onChange={handleChange}
                    />
                    
                    <label for="age" class="block text-xs font-semibold  uppercase">Age</label>
                    <input id="age" type="number" name="age" autocomplete="family-name"
                        class="block w-full p-3 mt-2  bg-slate-50 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formValue.age}
                    onChange={handleChange}
                    />
                    
                    <label for="address" class="block text-xs font-semibold  uppercase">Address</label>
                    <input id="address" type="text" name="address" autocomplete="family-name"
                        class="block w-full p-3 mt-2  bg-slate-50 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formValue.address}
                    onChange={handleChange}
                    />
                    <div>
                        <div class="row p-2 mt-3">
                            <label class="items-center ">
                                <input type="checkbox" class="form-checkbox h-5 w-5" 
                                name="monday"
                                value={formValue.monday}
                                onChange={handleChangeCheckbox}/>
                                <span class="ml-2">DAY 1 (Monday)</span>
                            </label>
                        </div>
                        <div class="row p-2">
                            <label class="items-center ">
                                <input type="checkbox" class="form-checkbox h-5 w-5"
                                name="tuesday"
                                value={formValue.tuesday}
                                onChange={handleChangeCheckbox}/>
                                <span class="ml-2">DAY 2 (Tuesday)</span>
                            </label>
                        </div>
                        <div class="row p-2">
                            <label class="items-center ">
                                <input type="checkbox" class="form-checkbox h-5 w-5"
                                name="wednesday"
                                value={formValue.wednesday}
                                onChange={handleChangeCheckbox}/>
                                <span class="ml-2">DAY 3 (Wednesday)</span>
                            </label>
                        </div>
                        <div class="row p-2">
                            <label class="items-center ">
                                <input type="checkbox" class="form-checkbox h-5 w-5"
                                name="thursday"    
                                value={formValue.thursday}
                                onChange={handleChangeCheckbox} />
                                <span class="ml-2">DAY 4 (Thursday)</span>
                            </label>
                        </div>
                        <div class="row p-2">
                        <label class="items-center ">
                            <input type="checkbox" class="form-checkbox h-5 w-5"
                            name="friday"    
                            value={formValue.friday}
                            onChange={handleChangeCheckbox}/>
                            <span class="ml-2">DAY 5 (Friday)</span>
                        </label>
                        </div>
                    </div>
                    <button hidden={disabledForm} type="submit" class="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none">
                       Create
                    </button>
                    <button onClick={goBackBtn} class="w-full py-3 mt-3 font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none">
                       Back
                    </button>
                </form>
            </div>
        </div>
    )
}

export default AddStudent