import { useState, useEffect } from 'react';
import Student from './Student';
import AddStudent from './AddStudent';
import ReportsList from './ReportsList';
import axios from 'axios';

function Main() {

    const [studentDetails, setStudentDetails] = useState('');
    const [studentList, setStudentList] = useState('');
    const [searchWord, setSearchWord] = useState('');
    const [addBtn, setAddBtn] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [studentFoundCount, setStudentFoundCount] = useState('');


    function getStudentSearch(value) {
        setSearchWord(value);
        axios.post('https://api.v2.pottershousephils.com/api/vbs2024/search', {
        "search" : value
        }).then(response => {
            console.log(response.data);
            setStudentFoundCount(response.data.data.length);
            setStudentList(response.data.data);
            setResponseMessage(response.data.message);
        });
    }

    function updateStudentAttendace(id, day) {
        axios.put(`https://api.v2.pottershousephils.com/api/vbs2024/${id}/${day}`).then(response => {
            getStudentSearch(searchWord);
        });
    }
    
    return (
        <div class="bg-grey-lighter  flex flex-col">
            <div class="max-w-sm mx-auto flex-1 flex flex-col items-center justify-center">
                
                <ReportsList />
                
                <div class="bg-white rounded shadow-md text-black w-full">
                    <h1 class="mb-8 text-3xl text-center">Search</h1>
                    {searchWord.length != 0 &&
                        <div>
                            {responseMessage} <h1>Found Result: {studentFoundCount}</h1>
                        </div>
                    }
                    <input
                        type="text"
                        class="block border border-grey-light w-full p-3 rounded mb-4"
                        name="fullname"
                        placeholder="Full Name, Address"
                        onKeyUp={(event) => { getStudentSearch(event.target.value) }}
                    />
                     
                    {studentList && !studentDetails && searchWord.length != 0 && studentList.map((student) => (
                        <div class="max-w-lg w-full rounded-lg bg-amber-100 shadow-lg p-4 mt-5">
                            <div></div>
                            <h3 class="font-semibold text-lg tracking-wide">{student.first_name} {student.last_name}</h3>
                            <p class="text-gray-500 my-1">
                                Age: {student.age}
                            </p>
                            <p class="text-gray-500 my-1">
                                Address: {student.address}
                            </p>
                            <div class="mt-2">
                                <div class="h-full flex justify-center items-center mt-3"  >
                                    <button onClick={() => updateStudentAttendace(student.id, 'monday')} class={`inline-flex items-center rounded-md px-3 py-3 text-md font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20
                                        ${student.monday ? 'bg-green-500' : 'bg-gray-300'}`}>DAY 1 (MONDAY)
                                    </button>
                                </div>
                                <div class="h-full flex justify-center items-center mt-3">
                                      <button onClick={() => updateStudentAttendace(student.id, 'tuesday')} class={`inline-flex items-center rounded-md px-3 py-3 text-md font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20
                                        ${student.tuesday ? 'bg-green-500' : 'bg-gray-300'}`}>DAY 2 (TUESDAY)
                                    </button>
                                </div>
                                <div class="h-full flex justify-center items-center mt-3">
                                    <button onClick={() => updateStudentAttendace(student.id, 'wednesday')} class={`inline-flex items-center rounded-md px-3 py-3 text-md font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20
                                        ${student.wednesday ? 'bg-green-500' : 'bg-gray-300'}`}>DAY 3 (WEDNESDAY)
                                    </button>
                                </div>
                                <div class="h-full flex justify-center items-center mt-3">
                                    <button onClick={() => updateStudentAttendace(student.id, 'thursday')} class={`inline-flex items-center rounded-md px-3 py-3 text-md font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20
                                        ${student.thursday ? 'bg-green-500' : 'bg-gray-300'}`}>DAY 4 (THURSDAY)
                                    </button>
                                </div>
                                <div class="h-full flex justify-center items-center mt-3">
                                    <button onClick={() => updateStudentAttendace(student.id, 'friday')} class={`inline-flex items-center rounded-md px-3 py-3 text-md font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20
                                        ${student.friday ? 'bg-green-500' : 'bg-gray-300'}`}>DAY 5 (FRIDAY)
                                    </button>
                                </div>
                            </div>
                        </div>
                     ))}
                    
                    {addBtn &&
                        <AddStudent details={studentDetails}/>
                    }

                    {!addBtn && !studentDetails &&
                        <div>
                            <button class="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-black shadow-lg 
                            focus:outline-none hover:bg-gray-900 hover:shadow-none" onClick={() => setAddBtn(true)}>
                                Create a New Student
                            </button>
                        </div>
                    }

                    {studentDetails && !addBtn &&
                        <Student details={studentDetails} />
                    }
                </div>

            </div>
        </div>
    )
}

export default Main