import { useEffect,useState } from 'react';
import theater from '../../files/theater.png';
import {useDispatch,useSelector} from 'react-redux';
import {fetchDramaScript,fetchDramaTypes} from '../../util/action';
import NotLoginPage from '../NotLoginPage';
import Loader from '../../component/Loader';

function DramaMinistry() {
    const authenticated = useSelector(state=>state.UserReducer.authenticated);
    const dramaTypes = useSelector(state=>state.MinistryReducer.dramaTypes);
    const dispatch = useDispatch();
    const dramaScript = useSelector(state=>state.MinistryReducer.dramaScript);
    const [type, setType] = useState('');
    const loader = useSelector(state=>state.MinistryReducer.loader);

    useEffect(()=>{
        dispatch(fetchDramaScript());
        dispatch(fetchDramaTypes());
    },[]);

    function sortType(value){
        dispatch(fetchDramaScript(value));
        setType(value);
    }

    return (
        <div>
            {loader  ? (
                    <div class="flex items-center justify-center min-h-screen">
                        <Loader/>
                    </div>
                ) :
                ( 
                    <div>
                    { authenticated ? 
                        <div>
                            <div class="w-screen h-sreen flex justify-center items-center pt-8">
                                {dramaTypes.map((dd,index) => [
                                    <div class="self-center grid grid-cols-1">
                                        <div class="p-2">
                                            <button type="button" onClick={() => sortType(dd.type)} key={index} className={(dd.type == type)? 'bg-stone-900 border-2 text-stone-100 p-2 rounded  leading-none flex items-center' : 'border-stone-900 border-2 text-stone-900 p-2 rounded  leading-none flex items-center'}>
                                                {dd.type} <span class={(dd.type == type)? 'bg-stone-100 p-1 rounded text-stone-900 text-xs ml-2' : 'bg-stone-900 p-1 rounded text-stone-100 text-xs ml-2'}>{dd.scripts}</span>
                                            </button>
                                        </div>
                                    </div>
                                ])}
                            </div>
                            <div aria-label="group of cards" tabindex="0" class="grid lg:grid-cols-2 sm:grid-cols-1 focus:outline-none py-8 w-full">
                                {dramaScript.map((scr,index) => [
                                    <div class="flex items-center justify-center">
                                        <div class="focus:outline-none lg:w-full m-7 bg-stone-100 p-6 shadow rounded"  key={index}>
                                            <div class="flex items-center border-b border-gray-200 pb-6">
                                                <img src={theater} alt="coin avatar" class="w-12 h-12 rounded-full" />
                                                <div class="flex items-start justify-between w-full">
                                                    <div class="pl-3 w-full">
                                                        <p  class="focus:outline-none text-xl font-medium leading-5 text-gray-800">{scr.title}</p>
                                                        <p  class="focus:outline-none text-sm leading-normal text-gray-500">Written By: {scr.author}</p>
                                                    </div>
                                                    <button onClick={() => {window.open(scr.link)}} class="bg-transparent hover:bg-stone-900 text-stone-900 font-semibold hover:text-white py-2 px-4 border border-stone-900 hover:border-transparent rounded">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="focus:outline-none" width="28" height="28" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="px-2">
                                                <p  class="focus:outline-none text-sm leading-5 py-4 text-gray-600">{scr.description}</p>
                                                <div tabindex="0" class="focus:outline-none flex">
                                                    <div class="py-2 px-4 text-xs leading-3 text-white rounded-full bg-stone-900">#{scr.type}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ])}
                            </div>
                        </div>
                        :
                        <NotLoginPage/>
                    }
                    </div>    
                )
            }
        </div>
    );
}
export default DramaMinistry;