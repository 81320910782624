import logo from '../files/phcc.png';

function Footer(){
    return(
        <footer class="text-gray-600 body-font bg-stone-900">
            <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
            <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                <img class="object-contain h-auto w-96" src={logo} />
                {/* <span class="ml-3 text-xl text-white">The Potter's House Christian Center</span> */}
                </a>
                <p class="mt-2 text-sm text-white">
                   #29 Mayon St. Boni Kaliwa Brgy. Malamig Mandaluyong City<br/>
                    401-6487 | 654-1782 | 0925-534-1149 <br/>
                    admin@pottershousephils.com
                </p>
            </div>
            <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 class="title-font font-bold text-white tracking-widest text-md mb-3">PIONEERING COUNTRIES</h2>
                <nav class="list-none mb-10 text-white">
                    <li>Vietnam</li>
                    <li>Cambodia</li>
                    <li>Myanmar</li>
                    <li>Laos</li>
                </nav>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 class="title-font font-bold text-white tracking-widest text-md mb-3">HELPFUL LINKS</h2>
                <nav class="list-none mb-10">
                    <li>
                    <a class="text-white hover:text-red-300" href="/">Home</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="about-us">About Us</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="chords">Chords</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="one80JAM">one80JAM</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="drama-ministry">Drama Scripts</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="sunday-school">Sunday School Curriculum</a>
                    </li>
                </nav>
                </div>
                {/* <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 class="title-font font-bold text-white tracking-widest text-md mb-3">MINISTRIES</h2>
                <nav class="list-none mb-10">
                    <li>
                    <a class="text-white hover:text-red-300" href="#">Music Ministry</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="#">Drama Ministry</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="#">Children's Ministry</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="#">Outreach Ministry</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="#">Youth Ministry</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="#">Bible Study</a>
                    </li>
                </nav>
                </div> */}
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 class="title-font font-bold text-white tracking-widest text-md mb-3">WEBSITES</h2>
                <nav class="list-none mb-10">
                    <li>
                    <a class="text-white hover:text-red-300" href="https://www.prescottpottershouse.com/">Prescott Potter's House</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="https://cfmmap.org/">CFM Map</a>
                    </li>
                    <li>
                    <a class="text-white hover:text-red-300" href="http://www.worldcfm.com/">CFM WorldWide</a>
                    </li>
                </nav>
                </div>
            </div>
            </div>
            <div class="bg-stone-900">
            <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                <p class="text-white text-sm text-center sm:text-left">© 2020 -2022</p>
            </div>
            </div>
        </footer>
    );
}
export default Footer;