import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import revival from './../files/thumbnail/revival.jpg';
import sundayschool from './../files/thumbnail/sundayshool.png';
import concert from './../files/thumbnail/concert.png';
import outreach from './../files/thumbnail/outreach.png';

function Events() {
  return (
    <div>
        <Carousel>
            <div class='max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden mx-auto mt-8'>
                <div class='w-2 bg-gray-800'></div>

                <div class="overflow-hidden rounded-xl relative transform hover:-translate-y-2 transition ease-in-out duration-500 shadow-lg hover:shadow-2xl movie-item text-white movie-card" data-movie-id="438631">
                    <div class="absolute inset-0 z-10 transition duration-300 ease-in-out bg-gradient-to-t from-black via-gray-900 to-transparent"></div>
                    <div class="relative cursor-pointer group z-10 px-10 pt-10 space-y-6 movie_info" data-lity="" href="https://www.youtube.com/@ThePottersHouseMandaluyong/playlists">
                        <div class="poster__info align-self-end w-full">
                            <div class="h-32"></div>
                            <div class="space-y-6 detail_info">
                                <div class="flex flex-col space-y-2 inner">
                                    <a class="relative flex items-center w-min flex-shrink-0 p-1 text-center text-white bg-red-500 rounded-full group-hover:bg-red-700" data-unsp-sanitized="clean">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM9.555 7.168A1 1 0 0 0 8 8v4a1 1 0 0 0 1.555.832l3-2a1 1 0 0 0 0-1.664l-3-2z" clip-rule="evenodd"></path>
                                        </svg>
                                        <div class="absolute transition opacity-0 duration-500 ease-in-out transform group-hover:opacity-100 group-hover:translate-x-16 text-xl font-bold text-white group-hover:pr-2">YouTube</div>
                                    </a>
                                    <h3 class="text-2xl font-bold text-white" data-unsp-sanitized="clean">Sunday Services</h3>
                                    <div class="mb-0 text-lg text-gray-400">Sermons/Preaching</div>
                                </div>
                                <div class="flex flex-row justify-between datos">
                                    <div class="flex flex-col datos_col">
                                        <div class="popularity">Sunday AM</div>
                                        <div class="text-sm text-gray-400">9:00 AM</div>
                                    </div>
                                    <div class="flex flex-col datos_col">
                                        <div class="release">Sunday PM</div>
                                        <div class="text-sm text-gray-400">6:30 PM</div>
                                    </div>
                                    <div class="flex flex-col datos_col">
                                        <div class="release">Wednesday</div>
                                        <div class="text-sm text-gray-400">7:00 PM</div>
                                    </div>
                                </div>
                                <div class="flex flex-col overview">
                                    <div class="flex flex-col"></div>
                                    <div class="text-xs text-gray-400 mb-2">Overview:</div>
                                    <p class="text-xs text-gray-100 mb-6">
                                       The Potters House is a place where people can meet Jesus, engage in life-giving community, and everyone is welcome. We believe in creating a space where people can have authentic encounters with Christ, their lives changed, whilst discovering their gifts and using them for God's glory
                                    </p>
                                </div>
                            </div>
                            <div data-countdown="2021-09-15" class="absolute inset-x-0 top-0 pt-5 w-full mx-auto text-2xl uppercase text-center drop-shadow-sm font-bold text-white">WATCH LIVE</div>
                        </div>
                    </div>
                    <img class="absolute inset-0 transform w-full -translate-y-4" src={revival} />
                    <div class="poster__footer flex flex-row relative pb-10 space-x-4 z-10">
                        <a
                            class="flex items-center py-2 px-4 rounded-full mx-auto text-white bg-red-500 hover:bg-red-700"
                            href="https://www.youtube.com/@ThePottersHouseMandaluyong/playlists"
                            target="_blank"
                            data-unsp-sanitized="clean"
                        >
                            <div class="text-sm text-white ml-2">Watch Now</div>
                        </a>
                    </div>
                </div>
            </div>
            <div class='flex max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden mx-auto mt-8'>
                <div class='w-2 bg-gray-800'></div>

                <div class="overflow-hidden rounded-xl relative transform hover:-translate-y-2 transition ease-in-out duration-500 shadow-lg hover:shadow-2xl movie-item text-white movie-card" data-movie-id="438631">
                    <div class="absolute inset-0 z-10 transition duration-300 ease-in-out bg-gradient-to-t from-black via-gray-900 to-transparent"></div>
                    <div class="relative cursor-pointer group z-10 px-10 pt-10 space-y-6 movie_info" data-lity="" href="https://www.youtube.com/embed/aSHs224Dge0">
                        <div class="poster__info align-self-end w-full">
                            <div class="h-32"></div>
                            <div class="space-y-6 detail_info">
                                <div class="flex flex-col space-y-2 inner">
                                    <h3 class="text-2xl font-bold text-white" data-unsp-sanitized="clean">Sunday School</h3>
                                    <div class="mb-0 text-lg text-gray-400">Children's Church</div>
                                </div>
                                <div class="flex flex-row justify-between datos">
                                    <div class="flex flex-col datos_col">
                                        <div class="popularity">Toddler's</div>
                                        <div class="text-sm text-gray-400">4-6 years old</div>
                                    </div>
                                    <div class="flex flex-col datos_col">
                                        <div class="release">Primary</div>
                                        <div class="text-sm text-gray-400">7-9 years old</div>
                                    </div>
                                    <div class="flex flex-col datos_col">
                                        <div class="release">Junior High</div>
                                        <div class="text-sm text-gray-400">10-13 years old</div>
                                    </div>
                                </div>
                                <div class="flex flex-col overview">
                                    <div class="flex flex-col"></div>
                                    <div class="text-xs text-gray-400 mb-2">Overview:</div>
                                    <p class="text-xs text-gray-100 mb-6">
                                       (For those with young children, there is childcare available and activities for all age groups, so you won't miss out.) We are a family, so expect to feel welcomed and meet different people with different backgrounds with the same story, ‘Jesus Christ changed my life’! Take a look at our church
                                    </p>
                                </div>
                            </div>
                            <div data-countdown="2021-09-15" class="absolute inset-x-0 top-0 pt-5 w-full mx-auto text-2xl uppercase text-center drop-shadow-sm font-bold text-white">JOIN US</div>
                        </div>
                    </div>
                    <img class="absolute inset-0 transform w-full -translate-y-4" src={sundayschool} />
                </div>
            </div>
            <div class='flex max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden mx-auto mt-8'>
                <div class='w-2 bg-gray-800'></div>

                <div class="overflow-hidden rounded-xl relative transform hover:-translate-y-2 transition ease-in-out duration-500 shadow-lg hover:shadow-2xl movie-item text-white movie-card" data-movie-id="438631">
                    <div class="absolute inset-0 z-10 transition duration-300 ease-in-out bg-gradient-to-t from-black via-gray-900 to-transparent"></div>
                    <div class="relative cursor-pointer group z-10 px-10 pt-10 space-y-6 movie_info" data-lity="" href="https://www.youtube.com/embed/aSHs224Dge0">
                        <div class="poster__info align-self-end w-full">
                            <div class="h-32"></div>
                            <div class="space-y-6 detail_info">
                                <div class="flex flex-col space-y-2 inner">
                                    <h3 class="text-2xl font-bold text-white" data-unsp-sanitized="clean">one80JAM Concert</h3>
                                    <div class="mb-0 text-lg text-gray-400">Outreach Ministry</div>
                                </div>
                                  <div class="flex flex-row justify-between datos">
                                    <div class="flex flex-col datos_col">
                                        <div class="release">Prayer</div>
                                        <div class="text-sm text-gray-400">6:00 PM</div>
                                    </div>
                                    <div class="flex flex-col datos_col">
                                        <div class="popularity">Concert</div>
                                        <div class="text-sm text-gray-400">7:00 PM</div>
                                    </div>
                                </div> 
                                <div class="flex flex-col overview">
                                    <div class="flex flex-col"></div>
                                    <div class="text-xs text-gray-400 mb-2">Overview:</div>
                                    <p class="text-xs text-gray-100 mb-6">
                                        Live Concert and Drama on youtube every Saturday at 7pm. we focus on personal evangelism throughout the tri-city area every Saturday night.Revise Christian version and live skit is presented to you.
                                    </p>
                                </div>
                            </div>
                            <div data-countdown="2021-09-15" class="absolute inset-x-0 top-0 pt-5 w-full mx-auto text-2xl uppercase text-center drop-shadow-sm font-bold text-white">JOIN US</div>
                        </div>
                    </div>
                    <img class="absolute inset-0 transform w-full -translate-y-4" src={concert} />
                </div>
              </div>
              <div class='flex max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden mx-auto mt-8'>
                <div class='w-2 bg-gray-800'></div>

                <div class="overflow-hidden rounded-xl relative transform hover:-translate-y-2 transition ease-in-out duration-500 shadow-lg hover:shadow-2xl movie-item text-white movie-card" data-movie-id="438631">
                    <div class="absolute inset-0 z-10 transition duration-300 ease-in-out bg-gradient-to-t from-black via-gray-900 to-transparent"></div>
                    <div class="relative cursor-pointer group z-10 px-10 pt-10 space-y-6 movie_info" data-lity="" href="https://www.youtube.com/embed/aSHs224Dge0">
                        <div class="poster__info align-self-end w-full">
                            <div class="h-32"></div>
                            <div class="space-y-6 detail_info">
                                <div class="flex flex-col space-y-2 inner">
                                    <h3 class="text-2xl font-bold text-white" data-unsp-sanitized="clean">Outreach</h3>
                                    <div class="mb-0 text-lg text-gray-400">Outreach Ministry</div>
                                </div>
                                 <div class="flex flex-row justify-between datos">
                                    <div class="flex flex-col datos_col">
                                        <div class="popularity">Sunday</div>
                                        <div class="text-sm text-gray-400">3:00 PM</div>
                                    </div>
                                    <div class="flex flex-col datos_col">
                                        <div class="release">Tuesday</div>
                                        <div class="text-sm text-gray-400">6:30 PM</div>
                                    </div>
                                </div>
                                <div class="flex flex-col overview">
                                    <div class="flex flex-col"></div>
                                    <div class="text-xs text-gray-400 mb-2">Overview:</div>
                                    <p class="text-xs text-gray-100 mb-6">
                                        In addition to One80, our weekly concert outreach ministry, we focus on personal evangelism throughout the tri-city area every Sunday afternoon (3pm) and Tuesday night at (7pm)
                                    </p>
                                </div>
                            </div>
                            <div data-countdown="2021-09-15" class="absolute inset-x-0 top-0 pt-5 w-full mx-auto text-2xl uppercase text-center drop-shadow-sm font-bold text-white">JOIN US</div>
                        </div>
                    </div>
                    <img class="absolute inset-0 transform w-full -translate-y-4" src={outreach} />
                </div>
            </div>
        </Carousel>
    </div>
  )
}

export default Events