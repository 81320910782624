import React from 'react'

function NotLoginPage() {
  return (
    <div class="flex items-center justify-center min-h-screen bg-stone-900 text-stone-200">
        <div class="flex flex-col items-center">
            <div class="font-bold text-3xl lg:text-9xl md:text-4xl">
                You need to Log In
            </div>
            <div class="text-center text-base lg:text-2xl md:text-base xs:text-tiny mt-10">
               If you want any further assistance email us: admin@pottershousephils.com
            </div>
        </div>
    </div>
  )
}

export default NotLoginPage