function BannerLive(){
    return(
        <div class="fixed inset-x-0 lg:inset-x-auto bottom-6 lg:right-8 xl:right-10 xl:bottom-8">
            <div class="lg:w-96 px-6 lg:px-0">
                <div class="p-2 bg-red-700 rounded-lg shadow-lg sm:p-3">
                    <div class="flex flex-wrap items-center justify-between">
                        <a target="_blank" href="http://youtube.com/" class="flex items-center flex-1 w-0">
                            <span class="flex p-2 bg-red-800 rounded-lg">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </span>

                            <p class="ml-3 font-medium tracking-wide text-white truncate">
                                Watch Live Service
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BannerLive;