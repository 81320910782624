function ErrorPage(){
    return(
        <div class="flex items-center justify-center min-h-screen bg-stone-900 text-stone-200">
            <div class="flex flex-col items-center">
                <div class="font-bold text-9xl">
                    404
                </div>
                <div class="font-bold text-center text-3xl xl:text-7xl lg:text-4xl md:text-5xl mt-10">
                    This page does not exist
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;