function Loader(){
    return(
        <div class="flex items-center justify-center space-x-2 animate-bounce">
            <h1 class="text-xl md:text-2xl font-bold leading-tight text-center">Loading </h1>
            <div class="w-2 h-2 bg-blue-800 rounded-full"></div>
            <div class="w-2 h-2 bg-blue-800 rounded-full"></div>
            <div class="w-2 h-2 bg-blue-800 rounded-full"></div>
        </div>
    );
}
export default Loader;