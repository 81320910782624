function Student(props) {

    return (
         <div class="grid  place-items-center">
            <div class="w-11/12 p-12 bg-white sm:w-8/12 md:w-1/2 lg:w-5/12">
                <h1 class="text-xl font-semibold"> Student Details</h1>
                <form class="mt-6">
                    <label for="firstname" class="block font-semibold text-gray-600 uppercase">{props.details.first_name}</label>
                   
                    <label for="lastname" class="block  font-semibold text-gray-600 uppercase">{props.details.last_name}</label>
                  
                    <label for="lastname" class="block font-semibold text-gray-600 uppercase">{props.details.address}</label>
                  
                     <div class="row mt-8">
                       <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                            DAY 1 (Monday)
                        </button>
                    </div>
                     <div class="row">
                        <label class="items-center mt-3">
                            <input type="checkbox" name="tuesday" class="form-checkbox h-5 w-5 text-gray-600" />
                            <span class="ml-2 text-gray-700">DAY 2 (Tuesday)</span>
                        </label>
                    </div>
                     <div class="row">
                        <label class="items-center mt-3">
                            <input type="checkbox" name="wednesday" class="form-checkbox h-5 w-5 text-gray-600" />
                            <span class="ml-2 text-gray-700">DAY 3 (Wednesday)</span>
                        </label>
                    </div>
                    <div class="row">
                        <label class="items-center mt-3">
                            <input type="checkbox" name="thursday" class="form-checkbox h-5 w-5 text-gray-600" />
                            <span class="ml-2 text-gray-700">DAY 4 (Thursday)</span>
                        </label>
                    </div>
                    <div class="row">
                        <label class="items-center mt-3">
                            <input type="checkbox" name="friday" class="form-checkbox h-5 w-5 text-gray-600" />
                            <span class="ml-2 text-gray-700">DAY 5 (Friday)</span>
                        </label>
                    </div>
                    <button class="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-black shadow-lg 
                    focus:outline-none hover:bg-gray-900 hover:shadow-none">
                       Update
                    </button>
                    <button class="w-full py-3 mt-6 font-medium tracking-widest text-white 
                    uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none">
                       Back
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Student