import React from 'react';

function MessageAlert(props){
    return(
        <div class={props.open}>
            <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                <div class="flex">
                    <div>
                        <p class="font-bold">Requested Sent!</p>
                        <p class="text-sm">You have requested to admin this song {props.text}.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessageAlert;