import axios from 'axios';

const userToken = localStorage.getItem('phcc_token');
const config ={ headers: {
    "Authorization" : `Bearer ${userToken}`,
    "Application" : `application/json`
}};

export const fetchCurriculum = () => {
    return dispatch => {
        axios.get('curriculum',config)
        .then(response => {
            dispatch({
                type: 'FETCH_CURRICULUM',
                payload: response.data
            })
        }).catch(error => dispatch({
            type: 'FETCH_CURRICULUM',
            loader: false
        }));
    }
}

export const fetchUser = () => {
    return dispatch => {
        axios.get('user',config)
        .then(response => {
            dispatch({
                type: 'FETCH_USER',
                payload: response.data
            })
        });
    }
}
export const fetchDramaTypes = () => {
    return dispatch => {
        axios.get('drama/types',config)
        .then(response => {
            dispatch({
                type: 'FETCH_DRAMA_TYPES',
                payload: response.data
            })
        }).catch(error => dispatch({
            type: 'FETCH_DRAMA_TYPES',
            loader: false
        }));
    }
}
export function fetchDramaScript(type = null) {
    return dispatch => {
        axios.get('drama?types='+type,config)
        .then(response => {
            dispatch({
                type: 'FETCH_DRAMA_SCRIPT',
                payload: response.data
            })
        }).catch(error => dispatch({
            type: 'FETCH_DRAMA_SCRIPT',
            loader: false
        }));
    }
}

export const getGoogleLink = () => {
    return dispatch => {
        axios.get('auth/google/url') 
        .then(function (response) {
            dispatch({
                type: 'FETCH_GOOGLE_LINK',
                payload: response.data
            })
        });
    }
}

export const setToken = (search) => {
    return dispatch => {
        axios.get(`auth/google/callback${search}`, 
        { headers: {
            "Application" : `application/json`
        }
        }).then(response => {
            localStorage.setItem('phcc_token', response.data);
            dispatch({
                type: 'SET_TOKEN',
                payload: response.data
            });
            window.location.href= 'login';
        });
    }
}

export const fetchOneSong = () => {
    return dispatch => {
        axios.get('one80Jam',config)
        .then(response => {
            dispatch({
                type: 'FETCH_ONE80JAM_SONG',
                payload: response.data.songs,
                tags: response.data.hashtags
            })
        }).catch(error => dispatch({
            type: 'FETCH_ONE80JAM_SONG',
            loader: false
        }));
    }
}

export const fetchTagSong = (id,name) => {
    return dispatch => {
        axios.get('one80Jam/tags/'+id,config)
        .then(response => {
            dispatch({
                type: 'FETCH_ONE80JAM_SONG_TAGS',
                payload: response.data,
                tag: name
            })
        }).catch(error => dispatch({
            type: 'FETCH_ONE80JAM_SONG_TAGS',
            loader: false
        }));
    }
}

export const fetchSearchOne80Song = (term) => {
    return dispatch => {
        axios.get('one80Jam/search/'+term,config)
        .then(response => {
            dispatch({
                type: 'SEARCH_ONE80JAM_SONG_TAGS',
                payload: response.data.songs
            })
        }).catch(error => dispatch({
            type: 'SEARCH_ONE80JAM_SONG_TAGS',
            loader: false
        }));
    }
}

export const getOne80Song = (id) => {
    return dispatch => {
        axios.get('one80Jam/'+id,config)
        .then(response => {
            dispatch({
                type: 'GET_ONE80JAM_SONG',
                payload: response.data.songs,
                tags: response.data.tags
            })
        }).catch(error => dispatch({
            type: 'GET_ONE80JAM_SONG',
            loader: false
        }));
    }
}

export const getRandomPicture = () => {
    return dispatch => {
        axios.get('picture')
        .then(response => {
            dispatch({
                type: 'GET_RANDOM_PICTURE',
                payload: response.data.songs,
                randomImage: response.data.tags
            })
        }).catch(error => dispatch({
            type: 'GET_RANDOM_PICTURE',
            loader: false
        }));
    }
}

export const getConferenceLink = () => {
    return dispatch => {
        axios.get('conference')
        .then(response => {
            dispatch({
                type: 'FETCH_CONFERENCE',
                payload: response.data,
            })
        }).catch(error => dispatch({
            type: 'FETCH_CONFERENCE',
            loader: false
        }));
    }
}