import { useState, useEffect } from 'react';
import { createCP, parseCP, transpose, keys } from 'simplechordpro';
import GoogleDocsViewer from 'react-google-docs-viewer';
import ReactDOM from 'react-dom';
import React from 'react';
import Modal from 'react-modal';
function JGTransposer(props){
  
    const [chords, setChords] = useState(props.chords);  
    const [currentKey, setKey] = useState(props.chordsKey);  
    
    var keys = [
        { name: 'Ab',  value: 0,   type: 'F' },
        { name: 'A',  value: 0,   type: 'F' },
        { name: 'A#',  value: 2,   type: 'S' },
        { name: 'Bb',  value: 2,   type: 'F' },
        { name: 'B',   value: 3,   type: 'N' },
        { name: 'C',   value: 4,   type: 'N' },
        { name: 'C#',  value: 5,   type: 'S' },
        { name: 'Db',  value: 5,   type: 'F' },
        { name: 'D',   value: 6,   type: 'N' },
        { name: 'D#',  value: 7,   type: 'S' },
        { name: 'Eb',  value: 7,   type: 'F' },
        { name: 'E',   value: 8,   type: 'N' },
        { name: 'Em',   value: 8,   type: 'N' },
        { name: 'F',   value: 9,   type: 'N' },
        { name: 'F#',  value: 10,  type: 'S' },
        { name: 'Gb',  value: 10,  type: 'F' },
        { name: 'G',   value: 11,  type: 'N' },
        { name: 'G#',  value: 0,   type: 'S' }
    ];

    function handleTranspose(currentKey, newKey) 
    {
        setChords(transpose(chords,currentKey, newKey));
        setKey(newKey);
    }
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>
            {chords &&
                <div class="pt-8">
                    <h1 class="text-center uppercase font-medium">{props.title}</h1>
                    <div className="container">
                        <div class="transpose-keys pt-4 pr-8 pl-8">
                            {keys.map((key) => [
                                <a key={key.name} onClick={() => {
                                    handleTranspose(currentKey, key.name)}} class={(currentKey == key.name)? 'selected' : ''}>{key.name}</a>
                            ])}
                        </div>
                    </div>
                    <div className="row">
                        <div class="flex h-96 w-screen justify-center pr-8 pl-8 pt-4 text-xs">
                            <textarea readOnly class="resize-none flex-1" value={parseCP(chords)}/>
                        </div>
                        {props.fileid &&
                            <div>
                                <div class="flex ml-4 mr-4 mb-2 mt-4  align-center items-center justify-center">
                                    <button onClick={openModal} class="xl:w-5/6 bg-blue-500 text-white px-12 py-3 rounded-md text-1xl font-medium hover:bg-blue-700 transition duration-300">View in docs</button>
                                </div>
                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={closeModal}
                                    style={{
                                        overlay: {
                                        position: 'fixed',
                                        backgroundColor: 'rgba(255, 255, 255, 0.75)'
                                        },
                                        content: {
                                        position: 'absolute',
                                        border: '1px solid #ccc',
                                        background: '#fff',
                                        overflow: 'auto',
                                    
                                        WebkitOverflowScrolling: 'touch',
                                        borderRadius: '4px',
                                        outline: 'none',
                                        padding: '20px'
                                        }
                                    }}
                                >
                                <button class="bg-stone-900 border-2 text-stone-100 p-2 rounded  leading-none flex items-center" onClick={closeModal}>close</button>
                                <GoogleDocsViewer
                                    width="600px"
                                    height="780px"
                                    fileUrl={`https://docs.google.com/document/d/${props.fileid}/export?format=pdf`}
                                    />
                                </Modal>
                             </div>
                        }                       
                    </div>
                </div>
            }
        </div>
    );

}

export default JGTransposer;