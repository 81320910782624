import React from 'react'
import Parser from 'html-react-parser';
import {fetchTagSong} from '../../util/action';
import {useDispatch} from 'react-redux';

function Lyrics(props) {
    const dispatch = useDispatch();
    
    function setTag(id, name){
        dispatch(fetchTagSong(id, name));
    }

  return (
        <div>
            {props.tags &&
                <div class="pt-8">
                    <h1 class="text-center uppercase font-medium">{props.song.song_title}</h1>
                    <div class="container">
                        <div class="flex pt-4 pr-8 pl-8">
                            {props.tags.map((key) => [
                                <button type="button" onClick={() => setTag(key.id, key.name)}  class="py-2 px-4 text-xs leading-3 text-white rounded-full bg-red-900 m-2">#{key.name}</button>
                            ])}
                        </div>
                    </div>
                    { props.song.lyrics &&
                    <div class="row">
                        <div class=" justify-center pr-8 pl-8 pt-4 text-xs text-stone-100">
                         {Parser(props.song.lyrics)}
                        </div>
                    </div>
                    }
                </div>
            }
        </div>
    )
}

export default Lyrics