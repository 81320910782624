import React from "react";
import ReactDOM from "react-dom";
import { Carousel } from 'react-responsive-carousel';

function HomePage(){
    return(
        <div>
            <div class="w-full">
                <div class="relative bg-stone-900 mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
                   <img class="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0"  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg" alt="bg"/>
                   <img class="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg" alt="bg"/>
                   
                    <div class="w-11/12 sm:w-2/3 sm:mb-10">
                        <h1 class="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">
                            Changing Lives <br/>
                            Making Disciples<br/>
                            Reaching the World
                        </h1>
                    </div>
                </div>
            </div>
            <div class="px-12 mx-auto max-w-7xl mt-10">
                <div class="w-full mx-auto text-left md:w-11/12 xl:w-9/12">
                    <h1 class="text-center mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                        <span>SERVICE TIMES</span>
                    </h1>
                    <div class="flex justify-center">
                        <div class="bg-white rounded-lg w-full lg:w-3/6 xl:w-3/6 p-4 shadow">
                            <div>
                                <span class="text-gray-900 relative inline-block date uppercase font-medium tracking-widest">EVERY DAY</span>
                                <div class="flex mb-2 mt-2">
                                    <div class="w-5/12">
                                        <span class="text-sm text-gray-600 block">7:00am</span>
                                    </div>
                                    <div class="w-9/12">
                                    <span class="text-sm font-semibold block">MORNING PRAYER</span>
                                    </div>
                                </div>
                                <span class="text-gray-900 relative inline-block date uppercase font-medium tracking-widest">EVERY SUNDAY</span>
                                <div class="flex mb-2 mt-2">
                                    <div class="w-5/12">
                                        <span class="text-sm text-gray-600 block">9:00am</span>
                                    </div>
                                    <div class="w-9/12">
                                    <span class="text-sm font-semibold block">ADULT BIBLE CLASS</span>
                                    <span class="text-sm">Sunday School classes for all ages</span>
                                    </div>
                                </div>
                                <div class="flex mb-2 mt-2">
                                    <div class="w-5/12">
                                        <span class="text-sm text-gray-600 block">9:00am</span>
                                    </div>
                                    <div class="w-9/12">
                                    <span class="text-sm font-semibold block">CHILDREN'S CHURCH</span>
                                    <span class="text-sm">Sunday School classes for ages 2-13 years old</span>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-5/12">
                                    <span class="text-sm text-gray-600 block">10:00am</span>
                                    </div>
                                    <div class="w-9/12">
                                    <span class="text-sm font-semibold block">SUNDAY MORNING SERVICE</span>
                                    <span class="text-sm">Regular Service</span>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-5/12">
                                    <span class="text-sm text-gray-600 block">6:30:00pm</span>
                                    </div>
                                    <div class="w-9/12">
                                    <span class="text-sm font-semibold block">SUNDAY EVENING SERVICE</span>
                                    <span class="text-sm">Regular Service</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span class="text-gray-900 relative inline-block date uppercase font-medium tracking-widest">EVERY WEDNESDAY</span>
                                <div class="flex mb-2 mt-2">
                                    <div class="w-5/12">
                                        <span class="text-sm text-gray-600 block">7:00pm</span>
                                    </div>
                                    <div class="w-9/12">
                                    <span class="text-sm font-semibold block">EVENING SERVICE</span>
                                    <span class="text-sm">Regular Service</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span class="text-gray-900 relative inline-block date uppercase font-medium tracking-widest">SATURDAY ACTIVITIES</span>
                                <div class="flex mb-2 mt-2">
                                    <div class="w-5/12">
                                        <span class="text-sm text-gray-600 block">7:00pm</span>
                                    </div>
                                    <div class="w-9/12">
                                    <span class="text-sm font-semibold block">one80JAM Concert</span>
                                    <span class="text-sm">To be announced</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-12 mx-auto max-w-7xl mt-10">
                <div class="w-full mx-auto text-left md:w-11/12 xl:w-9/12 text-center">
                    <h1 class="text-center mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                        <span>LOCATION</span>
                    </h1>
                    <p class="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
                        <strong>Address:</strong> #29 Mayon St. Boni Kaliwa Brgy. Malamig Mandaluyong City<br/>
                        <strong>Phone:</strong> 401-6487 | 654-1782 <br/> 0925-534-1149<br/>
                        <strong>Email:</strong> admin@pottershousephils.com
                    </p>
                    <iframe style={{border:'0', width: '100%', height: '270px'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965.3606374644386!2d121.04508242923127!3d14.57384059731737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8458db5a0c7%3A0x76f79991d6f0502a!2sThe%20Potter&#39;s%20House%20Christian%20Center!5e0!3m2!1sen!2sph!4v1603335130891!5m2!1sen!2sph" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
            <div class="px-12 mx-auto max-w-7xl mt-10">
                <div class="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
                    
                </div>
            </div>
            
        </div>
    );
}
export default HomePage;