const initialState = {
    user: {},
    google: null,
    authenticated: localStorage.getItem('phcc_token'),
    loader: true
}

const UserReducer = (state=initialState, action) => {
    const newState = {...state};
    switch(action.type){
        case 'FETCH_USER':
            return {
                ...state,
                user: action.payload,
                loader: false
            }
        case 'FETCH_GOOGLE_LINK':
            return {
                ...state,
                google: action.payload,
                loader: false
            }
        case 'SET_TOKEN':
            return {
                ...state,
                loader: false
            }
    }
    return newState;
    
}

export default UserReducer;