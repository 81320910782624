import { getFilePlugin,RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';

function Main(props) {
    const getFilePluginInstance = getFilePlugin();
    const { Download } = getFilePluginInstance;

    return (
        <><Download>
            {(
                action: RenderDownloadProps
            ) => (
                <button onClick={action.onClick} class="mb-2 w-full inline-block px-6 py-2.5 bg-red-800 text-white font-medium text-xs leading-normal uppercase shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-900 active:shadow-lg transition duration-150 ease-in-out">
                    {props.conference.btn}
                </button>
            )}
        </Download><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
                <Viewer plugins={[getFilePluginInstance]} fileUrl={props.conference.file} />;
            </Worker></>
    );
}
export default Main;