import React from 'react'
import { useState, useEffect } from 'react';

function Tags(props) {
    const [tags, setTags] = useState([]);

     useEffect(()=>{
        fetch('http://localhost:8088/v1/one80Jam/'+props.id,
            { 
                method: 'get', 
                headers: new Headers({
                    "Authorization" : `Bearer ${localStorage.getItem('phcc_token')}`,
                    "Application" : `application/json`
            })
        })
        .then(response => {
            return response.json();
        }).then(responseData => {
            setTags(responseData.data.tags);
        })
    },[tags]);
    console.log(tags);
    return (
        <div>
            {tags.map((tag, index) => [
                <div class="flex justify-center items-center capitalize font-medium">
                    <div className="text-sky-200 bg-sky-600 border-sky-600 bg-white rounded-full border px-2 py-1 m-1">
                        <div class="text-xs font-normal leading-none max-w-full flex-initial">{tag}</div>
                    </div>
                </div>
             ])}
        </div>
    )
}

export default Tags